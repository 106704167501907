import React from "react";
import exerciseImage from "../../Assets/exerciseImage.svg";
import reportImage from "../../Assets/reportImage.svg";
import trainersImage from "../../Assets/trainersImage.svg";
import {
  DetailsCard,
  FeaturesSection,
  IntroSection,
  MobileAppSection,
} from "./../../Components";
import "./Home.scss";

export const Home = () => {
  return (
    <article>
      <IntroSection />
      <FeaturesSection />
      <section className="report">
        <div className="container">
          <DetailsCard
            description="Empower your journey with insightful reports. From daily wins to monthly milestones, GetSuper keeps you informed, motivated, and on track with comprehensive progress reports"
            title="Get your weekly, monthly, & daily progress reports"
            badge="reports"
            banner={reportImage}
            className="report-card reverse"
          />
        </div>
      </section>
      <section className="exercise">
        <div className="container">
          <DetailsCard
            badge="exercise"
            title="Select dedicated body part to train & exercise"
            description="Personalize your fitness journey. Choose specific body parts, follow expert routines, or craft your custom workouts with GetSuper's versatile exercise feature for a targeted and tailored training experience."
            banner={exerciseImage}
            className="exercise-card"
          />
        </div>
      </section>
      <section className="trainers">
        <div className="container">
          <DetailsCard
            badge="trainers"
            title="We have a wide variety of experienced trainers"
            description="Elevate your workouts with our certified trainers. Access a diverse range of expert-designed routines, crafted by seasoned professionals, and level up your fitness game with GetSuper's wealth of training expertise "
            banner={trainersImage}
            className="trainers-card reverse"
          />
        </div>
      </section>
      <MobileAppSection />
    </article>
  );
};
