import React from "react";
import appleStore from "../../Assets/appleStore.png";
import googleStore from "../../Assets/googleStore.png";
import underline from "../../Assets/underline.png";
import banner from "./../../Assets/banner.svg";

export const IntroSection = () => {
  return (
    <section className="banner">
      <div className="container">
        <div className="banner-content">
          <h1 className="py-3 text-center">
            Fast track your nutrition and{" "}
            <span>
              fitness
              <img src={underline} />
            </span>{" "}
            with <span className="text-primary">GetSuper</span>{" "}
          </h1>
          <p>
            Transform your wellness journey with GetSuper, the ultimate fitness
            app. Streamline your nutrition and fitness goals, effortlessly scan
            barcodes, log workouts, and stay on track with personalized insights
            for a healthier, fitter you.
          </p>
          <div className="banner-content-btn">
            <a
              href="https://apps.apple.com/kw/app/get-super/id1619953566"
              target="_blank"
              className=""
            >
              <img src={appleStore} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.getsuper"
              target="_blank"
              className=""
            >
              <img src={googleStore} />
            </a>
          </div>
        </div>
        <div className="banner-image">
          <img src={banner} className="banner-img" />
        </div>
      </div>
    </section>
  );
};
