import React from "react";
import appleStore from "../../Assets/appleStore.png";
import googleStore from "../../Assets/googleStore.png";
import mobileApp from "../../Assets/mobileApp.svg";

export const MobileAppSection = () => {
  return (
    <section className="mobileApp">
      <div className="container">
        <div className="mobileAppSec">
          <div className="mobileAppSec-content">
            <h2 className="">Download our mobile app today!</h2>
            <div className="mobileAppSec-content-btn">
              <a
                href="https://apps.apple.com/kw/app/get-super/id1619953566"
                target="_blank"
                className=""
              >
                <img src={appleStore} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.getsuper"
                target="_blank"
                className=""
              >
                <img src={googleStore} />
              </a>
            </div>
          </div>
          <div className="mobileAppSec-image">
            <img src={mobileApp} className="mobileAppSec-img" />
          </div>
        </div>
      </div>
    </section>
  );
};
