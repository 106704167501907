import React from "react";
import "./DetailsCard.scss";

export const DetailsCard = ({
  banner = "",
  description = "",
  title = "",
  badge = "",
  className = "",
}) => {
  return (
    <div className={`detailsCard ${className}`}>
      <div className="detailsCard-content">
        <div className="badge">
          <span>{badge}</span>
        </div>
        <h2 className="">{title}</h2>
        <p>{description}</p>
      </div>
      <div className="detailsCard-image">
        <img src={banner} className="detailsCard-img" />
      </div>
    </div>
  );
};
