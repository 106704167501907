import React from "react";
import instalogo from "../../Assets/instalogo.svg";
import footerLogo from "../../Assets/logo.svg";

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-top">
          <div className="footer-logo">
            <a href="/">
              <img src={footerLogo} />
            </a>
          </div>
          <div className="footer-insta">
            <a href="https://www.instagram.com/getsuperapp/" target="_blank">
              <img src={instalogo} /> <span>@GetSuper</span>
            </a>
          </div>
        </div>
        <div className="copyRight">
          Copyright {new Date().getFullYear()} GetSuper. All rights reserved.
        </div>
      </div>
    </footer>
  );
};
