export const EmailIcon = ({ className = "" }) => {
  return (
    <i className={`cIcon cIcon-Email ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentcolor"
        viewBox="0 0 22 19"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 0.200684C0.585786 0.200684 0.25 0.53647 0.25 0.950684V17.9507C0.25 18.3649 0.585786 18.7007 1 18.7007H21C21.4142 18.7007 21.75 18.3649 21.75 17.9507V0.950684C21.75 0.53647 21.4142 0.200684 21 0.200684H1ZM5.41603 4.82666L4.58398 6.07474L11 10.3521L17.416 6.07474L16.584 4.82666L11 8.54931L5.41603 4.82666Z"
          fill="currentcolor"
        />
      </svg>
    </i>
  );
};
