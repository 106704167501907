import React from "react";
import caloriesIntake from "./../../Assets/caloriesIntake.png";
import customizedMeals from "./../../Assets/customizedMeals.png";
import featuresImage from "./../../Assets/featuresImage.svg";
import stepsTracking from "./../../Assets/stepsTracking.png";
import waterReminders from "./../../Assets/waterReminders.png";
import { DetailsCard } from "./DetailsCard";

export const FeaturesSection = () => {
  const featuresList = [
    {
      img: caloriesIntake,
      title: "calories intake",
      description:
        "Track calories effortlessly – log every meal with precision for a fit and focused journey.",
    },
    {
      img: stepsTracking,
      title: "steps tracking",
      description:
        "Step confidently towards your goals! GetSuper syncs seamlessly with Apple HealthKit and Google Health Connect for effortless step tracking.",
    },
    {
      img: waterReminders,
      title: "water reminders",
      description:
        "Hydrate smarter with our Water Reminder feature – set goals, log sips, thrive",
    },
    {
      img: customizedMeals,
      title: "customized meals",
      description:
        "Scan, eat, repeat! Effortlessly add meals by scanning food items, making meal logging a breeze for every craving and every meal type.",
    },
  ];
  return (
    <section className="features">
      <div className="container">
        <DetailsCard
          title="Set your customized exercise plan based on your routine "
          badge="features"
          banner={featuresImage}
          className="features-card"
        />
        <div className="features-list">
          {featuresList.map((feature, index) => (
            <div className="features-list-card" key={index}>
              <div className="image">
                <img src={feature.img} alt="" />
              </div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
