import React from "react";
import logo from "../../Assets/logo.svg";
import { EmailIcon } from "../Icons";
import "./Layout.scss";

export const Header = () => {
  return (
    <header>
      <div className="container">
        <div className="headerSection">
          <div className="logo">
            <a href="/">
              <img src={logo} />
            </a>
          </div>
          <div className="right-nav">
            <a href="mailto:support@getsuper.com" className="btn btn-primary">
              <EmailIcon />
              Contact us
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
